:root {
  --nav-height: 80px; /* adjust this value to match the height of your nav */
}

html, body {
  overflow-x: hidden;
  height: auto;
}


.neumorphic {
  background: #FF7900;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
              -8px -8px 16px rgba(0, 0, 0, 0.2);
}

span {
  font-size: 12px;
}

.hero {
  width: 100vw; /* full width of the viewport */
  min-height: calc(90vh - var(--nav-height)); /* full height of the viewport minus the height of the nav */
  height: auto;
  position: relative;
}

.color-box {
  transition: background-color 0.3s ease;
}

.color-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}


.navbar {
  z-index: 1000;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.tippy-tooltip {
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
}

#landing-page {
    min-height: calc(90vh - var(--nav-height));
    background: url('./assets/images/background.webp');
    background-color: rgba(255, 255, 255, 0.5);
    background-size: cover;
    -webkit-animation: AnimationName 46s ease infinite;
       -moz-animation: AnimationName 46s ease infinite;
       animation: AnimationName 46s ease infinite;
   }

   @-webkit-keyframes AnimationName {
       0%{background-position:51% 0%}
       50%{background-position:50% 100%}
       100%{background-position:51% 0%}
   }
   @-moz-keyframes AnimationName {
       0%{background-position:51% 0%}
       50%{background-position:50% 100%}
       100%{background-position:51% 0%}
   }
   @keyframes AnimationName {
       0%{background-position:51% 0%}
       50%{background-position:50% 100%}
       100%{background-position:51% 0%}
   }



p {
 color: white;
}

#computers {
  background: solid;
  background-color: #fff;
}

#typed {
  height: 50px;
}

#banner {
  height: 250px;
}
