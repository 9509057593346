@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy-Light.woff2') format('woff2'),
        url('../../assets/fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
        url('../../assets/fonts/AvenirNextLTPro-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gilroy', sans-serif;
}


p {
  font-family: 'Avenir Next LT Pro';
}

.nav-link {
  font-family: 'Avenir Next LT Pro';
  font-weight: normal;
}


body, button, input, textarea, a, li, span {
  font-family: 'Avenir Next LT Pro', sans-serif;
}

/* Any other specific tags you want to style */
.tag-name {
  font-family: 'Avenir Next LT Pro', sans-serif;
}
